//---------------
// Ebook Post
//---------------

// PACKAGES
import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

// COMPONENTS
// import Image from "../assets/images/masthead-booklet.png" // Tell Webpack this JS file uses this image
import Logo from "../assets/logos/ie-base-code.svg"
import MenuBack from "../assets/icons/menu-back.svg"

// DATA QUERY
export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            frontmatter {
                title
                date
                pdf {
                    publicURL
                  }
            }
            html
        }
    }
`


// COMPONENT BUILD
const Ebook = props => {
    
    const pdfPresent = props.data.markdownRemark.frontmatter.pdf;
    const pageToPreview = 1;
    
    // JSX TEMPLATE
    return (
        <section>
             <Helmet defer={false} defaultTitle='Informeasy | House manual booklet for vacation rental'>
                <html lang='en' />
                <meta name="viewport" content="initial-scale=1, shrink-to-fit=no, user-scalable=no, width=device-width"/>
                <meta name="robots" content="noindex" />
                <noscript>Please eneable Javascript or use a modern web browser!</noscript>
            </Helmet> 
            <div>
                {/* <div
                    dangerouslySetInnerHTML={{
                        __html: props.data.markdownRemark.html,
                    }}></div> */}
                <div className="container-fluid">
                    <nav className="navbar px-4 fixed-top navbar-expand-md navbar-light bg-white border-bottom border-ie-lighter py-2 align-self-autopy-md-3">
                        <div className="container">
                            <Link to="/">
                                <img
                                    className="logo-size"
                                    src={Logo}
                                    alt="Informeasy"
                                />
                            </Link>
                            <button
                                className="navbar-toggler border-0 px-2 py-1"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navigations-08"
                                aria-controls="navigations-08"
                                aria-expanded="false"
                                aria-label="Toggle menu">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div
                                className="collapse navbar-collapse"
                                id="navigations-08">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link menu-link mr-md-5 py-6 py-md-2"
                                            to="/">
                                            <img
                                                className="d-inline-block position-relative pb-1 mr-3"
                                                src={MenuBack}
                                                alt="Arrow-icon"
                                            />
                                            Visit homepage
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <div className="container text-center pt-8 pt-md-10">
                        <h1 className="masthead-title my-4">
                            {props.data.markdownRemark.frontmatter.title}
                        </h1>
                        <p className="masthead-lead px-4 px-md-0 mb-5">
                            Download this PDF to read our Home Guide offline on
                            the go.
                        </p>
                        {
                        pdfPresent === null ?(
                        <div className="alert alert-danger" role="alert">
                            <p className="d-inline mr-4">Shoot! Downloadable PDF is missing!</p>
                        <button
                            type="button"
                            className="d-inline px-6 btn btn-lg btn-secondary badge-pill text-ie-black"
                            data-toggle="modal"
                            data-target=".contact"
                            aria-label="Contact"
                            >
                            Let us know
                        </button>
                        </div>)
                        :
                        (<a
                            className="btn btn-primary btn-lg badge-pill px-4 mb-3 mb-md-4 shadow text-white    "
                            href={pdfPresent !== null && pdfPresent.publicURL}>
                            Open PDF
                        </a>)
                        }
                        <div className="flex mx-auto mx-w-sm justify-center">
                            <Document className="relative" file={pdfPresent !== null && pdfPresent.publicURL}>
                                <Page width="400" className="shadow-lg" renderTextLayer="false" renderAnnotationLayer="false" pageNumber={pageToPreview}/>
                            </Document>
                        </div>
                    </div>
                    <div className="modal fade contact bd-example-modal" data-backdrop="true" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="px-5 py-4 modal-header d-block">
                                    <h2 className="mb-3 modal-title w-100" id="exampleModalLabel">Contact us</h2>
                                    <p>Please allow us 48 hours to reply.</p>
                                </div>
                                <div className="modal-body">
                                    <form action="https://submit-form.com/lljMj3XITSHc8JxmHkA66">
                                        <div className="form-group">
                                            <input type="hidden" name="_email.subject" value="New Form Contact" />
                                            <input
                                                type="hidden"
                                                name="_redirect"
                                                value="https://informeasy.com//contact-thankyou/"
                                            />
                                            <label htmlFor="recipient-name" className="col-form-label text-ie-black">Name</label>
                                            <input type="text" name="name" className="form-control" id="recipient-name" aria-describedby="name field" placeholder="Add your name..." required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="recipient-email" className="col-form-label text-ie-black">Email</label>
                                            <input type="email" name="email" className="form-control" id="recipient-email" aria-describedby="email field" placeholder="Your email address here..." required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="message-text" className="col-form-label text-ie-black">Message:</label>
                                            <textarea type="text" name="message" className="form-control" id="message-text" aria-describedby="message box" placeholder="And your message..." rows="4" required />
                                        </div>
                                        <input
                                                type="checkbox"
                                                name="_honeypot"
                                                style={{display: `none`}}
                                                tabIndex="-1"
                                                autoComplete="off"
                                        />
                                        <div className="px-0 pt-2 pb-4 border-0 modal-footer">
                                            <button type="button" id="dismissModal" style={{lineHeight: `1.9rem`}} className="px-4 shadow-sm btn btn-lg btn-secondary badge-pill text-ie-black" data-dismiss="modal" aria-label="Close">Close</button>
                                            <button type="submit" style={{lineHeight: `1.9rem`}} className="px-4 text-white shadow btn btn-lg btn-primary badge-pill" aria-label="Submit contact" >Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {pdfPresent !== null ? (
                    <p className="mb-6 mt-5 text-center ">
                        Made by www.informeasy.com
                    </p>)
                    : ""}
                </div>
            </div>
            {/* <PdfPreview/> */}
        </section>
    )
}

export default Ebook
